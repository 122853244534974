.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: #000000;
	padding: 20px;
	border-radius: 25px;
	text-align: center;
	z-index: 1001;
	border: 3px solid #00eb7a;
	max-width: 80%;
	max-height: 200px;
}

.modal-content button {
	background-color: #000000;
	color: #00eb7a;
	border: 1px solid #00eb7a;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 24px;
	font-family: 'Montserrat', sans-serif;
	margin-top: 10px;
	cursor: pointer;
	border-radius: 10px;
	transition: background-color 0.3s;
}

.modal-content button:hover {
	background-color: #ffffff;
}
