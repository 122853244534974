.survival-school-form {
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  border-radius: 10px;
  z-index: 2;
  position: relative;
}

.p_Project{
    text-shadow: 2px 2px 4px rgb(65 186 237);
    font-size: 32px;
    text-align: center;
}
.p_Back{
    text-shadow: 2px 2px 4px rgb(237 65 65 / 82%);
    font-size: 32px;
    text-align: center;
}
.p_Front{
    text-shadow: 2px 2px 4px rgb(203 22 176 / 88%);
    font-size: 32px;
    text-align: center;
}
.p_Media{
    text-shadow: 2px 2px 4px rgb(123 70 8);
    font-size: 32px;
    text-align: center;
}
.media__checkbox{
    max-width: 20px;
}
.survival-school-form .tool {
    margin-bottom: 3px;
}
table{
    width: 100%;
}
.tool{
    display: flex;
    gap:10px;
    width: 200px;
}
.Form_Backend {
  background-image: url(../../images/deskback.svg);
  background-size: cover;
  background-repeat: no-repeat;

}
.Form_Frontend {
  background-image: url(../../images/Desktop_\ Front.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.Form_Project {
  background-image: url(../../images/Desktop_\ Project.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.Form_Media {
  background-image: url(../../images/Desktop_\ Media.svg);
  background-size: cover;
  background-repeat: no-repeat;
}


.Form {
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  background-color: transparent;
}
.survival-school-form div {
  margin-bottom: 20px;
}
th:first-child{
    text-align: start;
    padding-left: 0;
}
th{
    text-shadow: 2px 2px 4px rgb(203 22 176 / 88%);
}

.th_back:first-child{
  text-align: start;
  padding-left: 0;
}
.th_back{
  text-shadow: 2px 2px 4px rgba(203, 22, 22, 0.88);
}
.input_th{
  margin:0;
  font-size: 14px;
}
.experience_Project{
    max-width: 20px;
}
.td_back{
  min-width: 134px;
}

.media__checkbox{
  accent-color: #d58d05; 
}
.custom-checkbox2{
  accent-color: #eb0000c7; 
}
.custom-checkbox3{
  accent-color:  rgb(130 2 233); 
}
.custom-checkbox4{
  accent-color:  #0017df; 
}
.form__button {
        display: block;
        padding: 10px 20px;
        margin-top: 0px;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        background-color: transparent;
        color: white;
        border: 1px solid #d9d9d9;
        background-color: transparent;
        cursor: pointer;
        border-radius: 4px;
        font-size: 18px;
        max-width: 300px;
        border-radius: 60px;
        transition: background-color 0.3s;
        box-shadow: 0px 4px 10px #00eb7a;
        margin-bottom: 50px;
        margin-left: 0;
    margin-right: 0;
      }
      .backendButton{
        box-shadow: 0px 4px 10px #eb0000c7;
      }
      .frontButton{
        box-shadow: 0px 4px 10px rgb(130 2 233);
      }
      .mediaButton{
        box-shadow: 0px 4px 10px #d58d05;
      }
      .projectButton{
        box-shadow: 0px 4px 10px #0017df;
      }

      .backendButton:hover {
        background-color: #eb000063;
      }
      .backendButton:checked{
        background-color: #eb000063;
      }
      .frontButton:hover {
        background-color: rgba(129, 2, 233, 0.563);
      }
      .frontButton:checked{
        background-color: rgba(129, 2, 233, 0.466);
      }
      .mediaButton:hover {
        background-color: #d58c05ae;
      }
      .mediaButton:checked{
        background-color: #d58c05a5;
      }
      .projectButton:hover {
        background-color: #0016df73;
      }
      .projectButton:checked{
        background-color: #0016df73;
      }

.survival-school-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.survival-school-form textarea[type="text"],
.survival-school-form textarea,
.survival-school-form input,
.survival-school-form textarea[type="number"] {
  width: 96.8%;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.survival-school-form button {
  padding: 10px 20px;
  font-size: 20px;
  background-color: transparent;
  color: #fff;
  border: 1px solid;
  border-radius: 20px;
  cursor: pointer;
}


@media screen and (max-width: 660px) {
  .survival-school-form {
    max-width: 500px;
  }

  /*.survival-school-form textarea[type="text"],
  .survival-school-form textarea,
  .survival-school-form input,
  .survival-school-form textarea[type="number"] {
    width: 95.7%;
  }*/
}

@media screen and (max-width: 560px) {
  .survival-school-form {
    max-width: 400px;
  }
  .p {
    font-size: 24px;
  }
  /*.survival-school-form textarea[type="text"],
  .survival-school-form textarea,
  .survival-school-form input,
  .survival-school-form textarea[type="number"] {
    width: 94.9%;
  }*/
}

@media screen and (max-width: 460px) {
  .survival-school-form {
    max-width: 400px;
  }

  /*.survival-school-form textarea[type="text"],
  .survival-school-form textarea,
  .survival-school-form input,
  .survival-school-form textarea[type="number"] {
    width: 93.9%;
  }*/
  .p {
    font-size: 20px;
  }
  label {
    font-size: 14px;
  }
}

@media screen and (max-width: 360px) {
  .survival-school-form {
    max-width: 300px;
    padding: 13px;
  }


  label {
    font-size: 14px;
  }
}
.text {
  font-family: monospace;
  font-size: 18px;
  text-align: center;
  overflow: hidden;
  max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
  white-space: nowrap;
  margin-top: -20px;
  border-right: .15em solid #00eb7a; /* анимация мигания курсора */
  animation: typing 3s steps(40, end), blink-caret .75s step-end infinite;
}

/* анимация печатания текста */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* анимация мигания курсора */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #00eb7a;
  }
}



@media screen and (max-width: 320px) {
  .survival-school-form {
    max-width: 250px;
    padding: 13px;
  }

  .survival-school-form textarea[type="text"],
  .survival-school-form textarea,
  .survival-school-form input,
  .survival-school-form textarea[type="number"] {
    width: 91.5%;
  }

  label {
    font-size: 12px;
  }
}
