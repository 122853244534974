.main-page {
	text-align: center;
}
.itc {
	max-height: 1000px;
	max-width: 80%;
}
.message {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	box-shadow: 0px 4px 10px #00eb7a;
	border-radius: 20px;
	border: 2px solid black;
	z-index: 10000;
	color: black;
	font-size: 24px;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
}
@media screen and (max-width: 500px) {
	.message {
		font-size: 22px;
	}
}

.about__text:last-of-type {
	text-align: left;
	width: 100%;
}
@media screen and (max-width: 850px) {
	.about__text:last-of-type {
		text-align: center;
	}
}
.message1 {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	padding: 20px;
	box-shadow: 0px 4px 10px #00eb7a;
	border-radius: 20px;
	border: 2px solid black;
	z-index: 10000;
	color: black;
	font-size: 22px;
	text-align: center;
}
@media screen and (max-width: 500px) {
	.message1 {
		font-size: 20px;
	}
}
.page {
	max-width: 100%;
	min-height: 100%;
	padding-bottom: 0px;
	margin: 0;
	background-color: #131313;
	color: #c9c9c9;
	font-style: normal;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}
.main-page {
	background-color: #000000;
	background-size: contain;
}
.body {
	margin: 0;
}
.main__logo {
	max-width: 178px;
	max-height: 178px;
	position: absolute;
	left: 20px;
	top: 20px;
}
.footer__p {
	color: white;
	z-index: 10;
	/*margin-top: 80%;*/
	margin-top: -30px;
	margin-bottom: 0;
	padding-bottom: 10px;
	text-align: center;
	position: relative;
}
.code {
	width: 100%;
	text-shadow: 2px 2px 4px rgb(244 0 255);
}
.main__block {
	display: flex;
	padding-top: 60px;
	position: relative;
	z-index: 10;
	margin-right: 77px;
	margin-left: 77px;
}

.main__text-block {
	width: 100%;
	padding-top: 55px;
}
.main__header {
	font-size: 80px;
	margin-top: 0;
	margin-bottom: 25px;
	text-align: center;
	color: #00eb7a;
	text-shadow: 2px 2px 4px rgb(244 0 255);
}
.main__p {
	font-size: 40px;
	margin-top: 0;
	margin-bottom: 50px;
	color: #fff;
	text-align: center;
}

.header__block {
	display: flex;
}
.main__pBlock {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
}

.code-container {
	font-family: monospace;
	height: 211px;
	margin-top: 70px;
	display: flex;
	justify-content: center;
}

.code-line {
	max-width: 500px;
	overflow: hidden;
	white-space: nowrap;
	width: 0;
	color: white;
	text-align: start;
	animation: typing 4s steps(40, end) infinite;
}

@keyframes typing {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

.heart {
	z-index: 5;
	max-width: 534px;
	max-height: 402px;
}

.about__block {
	margin-top: 100px;
	display: flex;
	position: relative;
	z-index: 20;
	justify-content: space-around;
	align-items: center;
	margin-right: 77px;
	margin-left: 77px;
}
.block__section {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.about__text {
	max-width: 585px;
	font-size: 19.53px;
	text-align: left;
	margin-bottom: 0;
	color: #fff;
}
.about__title {
	font-size: 53.71px;
	align-self: start;
	margin-bottom: 19px;
	color: #fff;
	margin-top: 0;
}

.about__image {
	max-width: 539px;
	max-height: 579px;
}
.footerpic {
	width: 500px;
	margin-top: 100px;
}

.footer {
	position: fixed;
	bottom: 0;
}

.choiceWrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.choiceHeader {
	margin-bottom: 0;
	padding-top: 50px;
	margin-left: auto;
	margin-right: auto;
	font-size: 32px;
	font-weight: 800;
	width: max-content;
}

.choiceEl {
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	text-align: left;
	font-size: 24px;
	max-width: 800px;
}

.choiceText {
	width: 90%;
	font-size: 19.53px;
}

.next__text {
	color: #fff;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 40px;
	font-size: 50px;
	max-width: 1000px;
	margin-top: 150px;
}

.button {
	align-self: flex-end;
	transition: background-color 0.3s;
	box-shadow: 0 2px 4px rgba(202, 202, 202, 0.3);
	z-index: 2;
	color: #00eb7a;
	border-radius: 70px;
	padding: 5%;
	font-size: 35px;
	min-width: 411px;
	border: 1px solid #d9d9d9;
	background-color: transparent;
	cursor: pointer;
	font-family: 'Montserrat', sans-serif;
	box-shadow: 0px 4px 10px #00eb7a;
	text-shadow: 2px 2px 4px rgb(244 0 255);
}
.button:hover {
	background-color: #e2ece7;
}
.button:checked {
	background-color: #e2ece7;
}
@media screen and (max-width: 2236px) {
	.code-container {
		padding-left: 14%;
	}
}
@media screen and (max-width: 2000px) {
	/*.footer__p {
    margin-top: 10%;
  }*/
	.main__logo {
		left: 200px;
	}
	.code-container {
		padding-left: 13%;
	}
}
@media screen and (max-width: 1850px) {
	.main__logo {
		left: 150px;
	}
	.code-container {
		padding-left: 12%;
	}
}
@media screen and (max-width: 1700px) {
	.code-container {
		padding-left: 11%;
	}
	.main__logo {
		left: 130px;
		top: 35px;
	}
}
@media screen and (max-width: 1600px) {
	.code-container {
		padding-left: 10%;
	}
}
@media screen and (max-width: 1550px) {
	.main__logo {
		left: 100px;
	}
}
@media screen and (max-width: 1450px) {
	.code-container {
		padding-left: 8.5%;
	}
	.main__logo {
		left: 90px;
	}
}
@media screen and (max-width: 1350px) {
	.code-container {
		padding-left: 7.5%;
	}
	.main__logo {
		left: 50px;
	}
}

@media screen and (max-width: 1220px) {
	.main__block {
		margin-right: 60px;
		margin-left: 60px;
	}
	.about__block {
		margin-right: 60px;
		margin-left: 60px;
	}
	.button {
		left: 60px;
	}
	.main__p {
		font-size: 32px;
	}
}

@media screen and (max-width: 1190px) {
	.heart {
		max-width: 434px;
		top: 80px;
	}
	.main__header {
		font-size: 72px;
	}
	.main__p {
		font-size: 26px;
		margin-bottom: 40px;
	}
	.about__title {
		font-size: 50px;
	}

	.about__text {
		font-size: 17px;
	}

	.choiceText {
		font-size: 17px;
	}

	.block__section {
		max-width: 500px;
	}
}

@media screen and (max-width: 1100px) {
	.main__header {
		font-size: 64px;
		margin-top: 0;
	}
	.main__p {
		font-size: 22px;
	}
	.main__p2 {
		font-size: 16px;
	}
	.about__title {
		font-size: 46px;
	}
	.about__title2 {
		font-size: 46px;
	}
	.about__text {
		font-size: 16px;
	}

	.choiceText {
		font-size: 16px;
	}

	.about__image {
		max-width: 439px;
		max-height: 479px;
	}

	.block__section {
		max-width: 450px;
	}
	.text {
		font-size: 15px;
	}
}

@media screen and (max-width: 1028px) {
	.heart {
		max-width: 374px;
	}

	.block__section {
		max-width: 420px;
		padding-left: 10px;
	}
	.main__block {
		padding-top: 60px;
	}
	.next__text {
		font-size: 30px;
	}
}

@media screen and (max-width: 950px) {
	.block__section {
		max-width: 350px;
		padding: 0;
	}
	.about__image {
		max-width: 339px;
	}
	.about__title {
		font-size: 42px;
	}
	.about__title2 {
		font-size: 42px;
	}
	.about__block {
		margin-top: 150px;
	}
	.footerpic {
		width: 300px;
	}
}
@media screen and (max-width: 900px) {
	.heart {
		max-width: 354px;
	}
	.zakat {
		max-width: 508px;
	}
	.main__header {
		font-size: 52px;
	}
	.main__p {
		font-size: 18px;
	}
	.main__p2 {
		font-size: 15px;
		max-width: 400px;
	}
	.button {
		font-size: 20px;
		max-width: 80%;
		min-width: auto;
	}
}

@media screen and (max-width: 846px) {
	.main__header {
		font-size: 46px;
	}
	.main__p {
		font-size: 17px;
	}
	.main__p2 {
		max-width: 300px;
	}
	.zakat {
		top: 370px;
	}
	.koster2 {
		margin-top: 0px;
	}
	.about__block {
		flex-direction: column;
	}
	.about__title {
		width: 100%;
		text-align: center;
	}
	.about__text {
		width: 100%;
		text-align: center;
	}
	.block__section:nth-child(2) {
		flex-direction: column-reverse;
	}
	.block__section {
		max-width: 100%;
	}
	.about__image {
		max-width: 538px;
	}
	.gallery__image {
		max-width: 424px;
		max-height: 224px;
		margin-right: 30px;
	}
	.gallery__text {
		font-size: 82px;
	}
	.otziv__title {
		margin-top: 100px;
		font-size: 40px;
	}
	.gallery__block {
		margin-top: 50px;
	}
	.logo2 {
		max-width: 706px;
		max-height: 706px;
		top: 1820px;
	}
	.Next {
		font-size: 40px;
		margin-top: 100px;
	}
	.Name {
		font-size: 24px;
	}
}
@media screen and (max-width: 806px) {
	.heart {
		max-width: 324px;
	}
	.zakat {
		max-width: 480px;
	}
	.main__header {
		font-size: 46px;
	}
	.main__p {
		font-size: 17px;
	}
	.main__p2 {
		font-size: 14px;
	}
}

@media screen and (max-width: 790px) {
	.main__text-block {
		padding-top: 30px;
	}
	.main__logo {
		top: 5px;
		left: 30px;
	}
	.footer1 {
		width: 100%;
		justify-content: center;
	}
	.Next {
		margin-left: auto;
		margin-right: auto;
		max-width: 370px;
		text-align: center;
	}
	.button {
		margin-left: auto;
		margin-right: auto;
		position: unset;
	}
	/*.footer__p {
    margin-top: 350px;
  }*/
	.about__block {
		margin-top: 150px;
	}
	.main__block {
		margin-right: 50px;
		margin-left: 50px;
		padding-top: 60px;
	}
	.heart {
		max-width: 274px;
		top: 100px;
	}
	.zakat {
		max-width: 380px;
		top: 330px;
	}
	.main__header {
		font-size: 43px;
	}
	.main__p {
		font-size: 16px;
	}
	.main__p2 {
		font-size: 13px;
	}
	.main__logo {
		max-width: 108px;
		max-height: 108px;
	}
	.gallery__text {
		font-size: 79px;
	}
	.otziv__title {
		font-size: 36px;
		text-align: center;
	}
	.Next {
		font-size: 36px;
		margin-bottom: 50px;
	}
	.otziv__block {
		flex-direction: column;
		justify-content: center;
		max-width: 400px;
	}
	.otziv {
		max-width: 400px;
	}
	.logo2 {
		top: 1700px;
	}
	.koster2 {
		margin-top: 400px;
	}
	.button {
		text-wrap: nowrap;
		/*max-width: 80%;
		min-width: auto;
		font-size: 30px;*/
	}
	.about__text:first-of-type {
		margin-top: 0;
	}
}
@media screen and (max-width: 685px) {
	.iskri {
		max-width: 500px;
	}
	.main__block {
		margin-right: 40px;
		margin-left: 40px;
		padding-top: 40px;
	}
	.heart {
		max-width: 274px;
		top: 90px;
	}
	.zakat {
		max-width: 350px;
		top: 310px;
	}
	.main__header {
		font-size: 41px;
		margin-bottom: 20px;
	}
	.main__p {
		font-size: 15px;
		margin-bottom: 20px;
	}
	.main__p2 {
		font-size: 13px;
	}
	.main__logo {
		max-width: 98px;
		max-height: 98px;
	}
}

@media screen and (max-width: 698px) {
	.iskri {
		max-width: 500px;
	}
	.main__block {
		flex-direction: row-reverse;
		justify-content: space-between;
	}
	.main__text-block {
		margin-left: 0;
	}
	.heart {
		max-width: 274px;
		top: 70px;
	}
	.zakat {
		max-width: 350px;
		top: 310px;
	}
	.main__header {
		font-size: 41px;
		margin-bottom: 20px;
	}
	.main__p {
		font-size: 16px;
		margin-bottom: 20px;
	}
	.main__p2 {
		font-size: 16px;
	}
	.main__logo {
		max-width: 98px;
		max-height: 98px;
	}
	.about__block {
		margin-right: 40px;
		margin-left: 40px;
		margin-top: 150px;
	}
	.gallery__text {
		margin-left: 40px;
	}
	.gallery {
		margin-left: 40px;
	}
	.otziv__title {
		margin-left: 40px;
	}

	.logo2 {
		top: 1620px;
	}

	.footer__p {
		color: white;
		z-index: 10;
		/* margin-top: 250px;*/
		text-align: center;
	}
}

@media screen and (max-width: 610px) {
	.code-container {
		margin-top: 30px;
	}
	.about__block {
		margin-top: 100px;
	}
	.main__p {
		font-size: 28px;
		margin-top: 40px;
		max-width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	.next__text {
		margin-top: 50px;
	}
	.about__title {
		font-size: 36px;
	}

	/*.footer__p {
    margin-top: 250px;
  }*/
}
@media screen and (max-width: 582px) {
	.iskri {
		max-width: 450px;
	}
	.heart {
		max-width: 254px;
		top: 70px;
	}
	.zakat {
		max-width: 340px;
		top: 310px;
	}
	.main__logo {
		left: 10px;
	}
}
@media screen and (max-width: 555px) {
	.next__text {
		font-size: 20px;
		margin-bottom: 10px;
	}
}
@media screen and (max-width: 538px) {
	.iskri {
		max-width: 450px;
	}
	.heart {
		max-width: 244px;
		top: 70px;
	}
	.zakat {
		max-width: 310px;
		top: 310px;
	}
	.main__p2 {
		font-size: 12px;
		max-width: 280px;
	}
	.gallery__title {
		font-size: 36px;
	}
	.gallery__text {
		font-size: 60px;
	}
	.logo2 {
		top: 1440px;
	}
	.about__image {
		max-width: 438px;
	}
	.gallery__image {
		max-width: 364px;
		max-height: 204px;
	}
	.otziv__title {
		margin-bottom: 30px;
	}
	.koster2 {
		margin-top: 600px;
	}
	.gallery {
		margin-bottom: 20px;
	}
	.Next {
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 490px) {
	.zakat {
		top: 280px;
	}
	.about__title {
		margin-top: 0;
	}
	.logo2 {
		max-width: 506px;
		max-height: 506px;
		top: 1400px;
	}
}
@media screen and (max-width: 470px) {
	.main__block {
		/*flex-direction: row;*/
		justify-content: space-between;
		margin-right: 20px;
		margin-left: 20px;
		padding-top: 20px;
	}
	.main__logo {
		max-width: 58px;
		max-height: 58px;
	}
	.main__text-block {
		padding-top: 15px;
	}
	.iskri {
		max-width: 400px;
	}
	.heart {
		max-width: 204px;
		top: 0px;
	}
	.zakat {
		max-width: 260px;
		top: 260px;
	}
	.text {
		font-size: 14px;
	}
	.main__p2 {
		font-size: 14px;
		max-width: 220px;
	}
	.main__header {
		font-size: 32px;
	}

	.main__p {
		font-size: 20px;
		margin-top: 0;
	}
	.otziv {
		margin-left: auto;
		margin-right: auto;
		max-width: 300px;
	}
	.footerpic {
		width: 200px;
		margin-top: 50px;
	}
	.next__text {
		font-size: 18px;
	}
	.logo2 {
		top: 1180px;
	}
	/*.footer__p{
    margin-top: 150px;
  }*/
	.about__block {
		margin-top: 100px;
	}
	.about__title {
		font-size: 34px;
	}
	.about__title2 {
		font-size: 34px;
	}
	.gallery__title {
		font-size: 34px;
	}
	.otziv__title {
		font-size: 34px;
	}
	.koster2 {
		margin-top: 700px;
	}
	.Next {
		font-size: 34px;
	}
	.about__text {
		max-width: 100%;
		font-size: 14px;
	}

	.choiceText {
		font-size: 14px;
	}
	.otziv__block {
		max-width: 400px;
	}
}
@media screen and (max-width: 450px) {
	.heart {
		top: 30px;
	}
}
@media screen and (max-width: 400px) {
	.iskri {
		max-width: 300px;
		mask-image: none;
	}
	.about__title {
		font-size: 28px;
	}

	.button {
		max-width: 90%;
		min-width: auto;
		/*font-size: 50%;*/
	}

	.heart {
		max-width: 154px;
	}
}

@media screen and (max-width: 360px) {
	.next__text {
		font-size: 16px;
	}
}

@media screen and (max-width: 320px) {
	.gallery__title {
		font-size: 28px;
	}
	.gallery__text {
		font-size: 40px;
	}
	.otziv__title {
		font-size: 28px;
	}
	.koster2 {
		margin-top: 850px;
	}
}
