.questions {
	padding-left: 20px;
	padding-right: 20px;
	color: #fff;
	height: 100%;
	background-color: #131313;
}

.questions-container {
	max-width: 600px;
	margin: 0 auto;
	padding: 60px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
}

.questions-title {
	margin-bottom: 20px;
	font-size: 60px;
	margin-top: 0px;
	color: #fff;
	text-align: center;
}
.checkboxes {
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 10px;
}
/* Определение стиля для заполненного чекбокса */

.custom-checkbox1 {
	accent-color: #00eb7a;
}
.question-block {
	margin-bottom: 20px;
}

.question-label {
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
}

.question-input,
.question-select {
	width: 97.3%;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
	background-color: white;
	box-sizing: content-box;
	-ms-box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}

.question-select {
	box-sizing: border-box;
}

.next-button {
	display: block;
	width: 100%;
	padding: 10px 20px;
	margin-top: 0px;
	font-weight: bold;
	font-family: 'Montserrat', sans-serif;
	background-color: transparent;
	color: #00eb7a;
	border: 1px solid #d9d9d9;
	background-color: transparent;
	cursor: pointer;
	border-radius: 4px;
	font-size: 18px;
	max-width: 300px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 60px;
	transition: background-color 0.3s;
	box-shadow: 0px 4px 10px #00eb7a;
	margin-bottom: 50px;
}

.next-button:hover {
	background-color: #ffffff;
}
.next-button:checked {
	background-color: #ffffff;
}

.passport-inputs {
	display: flex;
}

.passport-input {
	flex: 1;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
	margin-right: 10px;
}

.error-message {
	color: red;
	font-size: 14px;
	margin-top: 5px;
}

.error-input {
	border-color: red;
}

.passport-input:last-child {
	margin-right: 0;
}
.background {
	position: fixed;
	height: 100vh;
	z-index: 0;
	right: 0px;
	opacity: 0.5;
}

.navButtons {
	display: flex;
	justify-content: space-between;
}

@media screen and (max-width: 750px) {
	.questions-title {
		font-size: 30px;
		text-align: center;
		margin-top: 20px;
	}

	.questions-container {
		padding: 10px;
	}
	.background {
		height: 80vh;
		width: 50%;
	}
}

@media screen and (max-width: 620px) {
	.questions-container {
		max-width: 400px;
	}

	.question-input {
		padding: 4.5px;
	}

	.passport-input {
		padding: 4.5px;
	}

	.block {
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
	}

	.question-select {
		padding: 4.5px;
	}
	.background {
		height: 60vh;
		width: 50%;
	}
}
@media screen and (max-width: 500px) {
	.background {
		height: 50vh;
		width: 50%;
	}
}

@media screen and (max-width: 430px) {
	.questions-container {
		max-width: 350px;
	}
	.background {
		height: 55vh;
		width: 50%;
	}
	.passport-input {
		max-width: 130px;
	}

	.question-input {
		width: 95.3%;
		font-size: 14px;
	}
}

@media screen and (max-width: 450px) {
	.questions-container {
		max-width: 350px;
	}

	.passport-input {
		max-width: 130px;
	}

	.question-input {
		width: 95.3%;
	}
}

@media screen and (max-width: 399px) {
	.questions-container {
		max-width: 310px;
	}

	.questions-title {
		font-size: 18px;
	}
	.background {
		height: 60vh;
		width: 55%;
	}
	.question-label {
		font-size: 15px;
	}

	.question-select {
		font-size: 14px;
	}

	.passport-input {
		font-size: 14px;
	}
}

@media screen and (max-width: 350px) {
	.questions-container {
		max-width: 290px;
	}

	.passport-input {
		max-width: 120px;
	}
}

@media screen and (max-width: 320px) {
	.questions-container {
		max-width: 270px;
	}

	.passport-input {
		max-width: 110px;
	}
}
